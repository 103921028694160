html,
body {
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-fullHeight-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
}

.flex-grow-shrink-auto {
  flex: 1 1 auto;
}

.flex-shrink-auto {
  flex: 0 1 auto;
}

#map.dashboardMapDiv .ol-viewport {
  border-radius: 4px;
}

.map-description-text {
  -webkit-text-stroke-width: 0.08rem;
  -webkit-text-stroke-color: #282d2f;
  color: white;
  position: relative;
  left: 0.55rem;
  margin-bottom: -0.25rem;
  font-family: "Roboto", "Arial Black", "Helvetica", "Verdana", "Impact",
    "Courier", "Arial";
  font-size: 1.9rem;
  font-weight: bold;
  z-index: 5;
  text-shadow: 0px 0px 2px #282d2f, 0px 0px 3px black;
}

.no-overflowx {
  overflow-x: hidden;
}

.no-overflowy {
  overflow-y: hidden;
}

.map-zoomIn {
  border-radius: 2px 2px 0 0;
  border-bottom: 1px lightgray solid !important;
}

.map-zoomOut {
  border-radius: 0 0 2px 2px;
  border-top: 1px lightgray solid !important;
  margin: -2px 1px 1px 1px !important;
}

.map-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.map-button {
  border-radius: 2px;
}

.map-control button {
  display: block;
  margin: 1px;
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.25em;
  background-color: white;
  color: black;
  border: black solid 1px;
}

.map-options {
  border: 0px;
}

.ol-zoom {
  z-index: 1080;
}

.ol-control button {
  line-height: 0.8rem !important;
}

.ol-attribution.ol-uncollapsible {
  height: 1.25em !important;
}

.legend-header {
  font-weight: 500;
}

.legend-seperator {
  margin-top: 0em !important;
  margin-bottom: 0.3em !important;
}

.bm-settings-container {
  padding: 8px;
  padding-bottom: 0px;
  display: flex !important;
  flex-direction: column;
  height: 100%;
}

.settings-datePicker {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  width: 100%;
}

.settings-fullWidthButton {
  margin-top: -4px !important;
  width: 100%;
}

.settings-select {
  width: 100%;
  margin-bottom: 8px !important;
}

.settings-246px {
  width: 100%;
  margin-bottom: 8px !important;
}

.bottom-button-divider {
  margin-top: 0.25em !important;
  margin-bottom: 0.5em !important;
}

.absolutePosition {
  position: absolute;
}

.fullWidth {
  width: 100%;
}

.mb-3 {
  margin-bottom: 0px !important;
}

.over-popover {
  z-index: 1080;
}

.no-margin {
  margin: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.margin-top-4px {
  margin-top: 4px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-bottom-4px {
  margin-bottom: 4px !important;
}

.margin-bottom-8px {
  margin-bottom: 8px !important;
}

.double-switch-settingsRow {
  margin-left: 0px;
  margin-right: -16px;
}

.map-load-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.map-color-swatch {
  height: 16px;
  width: 16px;
  position: relative;
  border-style: solid;
  border-width: 1px;
}

.map-color-label {
  margin-left: 24px;
  margin-top: -19px;
}

.dateTimeCell {
  font-size: 12px;
}

.smallFontCell {
  font-size: 12px;
}

#c3-chart {
  height: 80%;
  width: 100%;
  position: absolute;
  left: 0px;
}

.c3-axis-y text {
  font-size: 15px;
}

.c3-axis-x text {
  font-size: 13px;
}

.c3-axis-y-label {
  transform-origin: -6px 0px;
}

#cacheCadLabel {
  margin-left: 93px;
  text-align: center;
}

#statusDiv {
  font-size: 20px;
}

.settingsPage {
  width: 600px;
  margin: auto;
  margin-top: 30px;
}

.update-notification {
  background-color: #282d2f;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
}

.update-notifcation:hover {
  color: #fb7713;
}

.center {
  margin: auto;
}

.center-text {
  text-align: center;
}

.center-justify {
  justify-content: center;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.FileDropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.FileDropzoneHighlight {
  background-color: rgb(188, 185, 236);
}

.center-switch {
  padding-left: 0 !important;
}

.column-switch {
  padding-left: 2.25rem;
  padding-right: 0.75rem;
}

.flex-horizontal-center {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.flex-row-nopadding {
  padding-left: 0px;
}

.header-button {
  border: 0;
  width: 100%;
  padding: 0px;
  background: transparent;
  margin-left: -0.2em;
  line-height: normal;
  display: flex;
  flex-direction: row;
}

.header-button-icon {
  font-size: 1.5em;
  font-family: "Material Icons";
}

.header-button-text {
  margin-top: 0.05rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  font-size: 0.875rem;
}

.pad-bottom-p5em {
  padding-bottom: 0.5em;
}

.pad-gutter-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.pad-left-8px {
  padding-left: 8px;
}

.firewatch-logo {
  height: 40px;
  width: 152px;
  margin-left: -0.5rem;
}

.firewatch-settingsButton {
  margin-right: 16px !important;
}

@media (min-width: 400px) and (max-width: 600px) {
  .firewatch-logo {
    height: 40px;
    width: 152px;
    margin-left: -1.5rem;
    margin-top: 0.3rem;
  }

  .firewatch-navButton {
    padding: 4px 7px !important;
  }

  .MuiToolbar-gutters {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media (max-width: 600px) {
  .firewatch-logo {
    height: 40px;
    width: 152px;
    margin-left: 0rem;
    margin-top: 0.3rem;
  }

  .firewatch-settingsButton {
    margin-right: 0px !important;
    margin-left: -6px !important;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .firewatch-logo {
    height: 40px;
    width: 152px;
    margin-left: -1rem;
  }

  .firewatch-settingsButton {
    margin-right: 16px !important;
  }
}
