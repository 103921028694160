.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 16px;
  top: 74px;
}

.bm-overlay {
  z-index: 1099 !important;
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-menu {
  border: 0px;
  border-right: 1px darkgray;
  border-style: solid;
  background: white;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list {
  overflow-x: hidden;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
.bm-item:focus {
  outline: none;
}
.bm-cross {
  background: black;
}

.menu-1 .bm-cross {
  background: #bdc3c7;
}
.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-2 .bm-cross {
  background: #999;
}
.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}
.menu-2 a {
  padding: 1em;
}
.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
.menu-3 .bm-cross {
  background: #888;
}
.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
.menu-3 i {
  opacity: 0.5;
}
.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
.menu-4 .bm-cross {
  background: #888;
}
.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 h2 i {
  margin-left: 0.2em;
}
.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}
.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}
#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}
#bubble a,
#push a {
  padding: 1em;
}
#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
#elastic .bm-cross {
  background: #888;
}
#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
#elastic i {
  opacity: 0.5;
}
#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}
#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}
#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}
#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
